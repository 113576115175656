import { mapGetters, mapMutations} from "vuex";
import axios from "@/axios";
import { get } from "lodash";
import { DateTime} from "luxon";
import nLoader from "@shared/components/n-loader";
import {JURISDICTION_MAP} from "@shared/utils/constants";
import neoSwitch from "@shared/components/toggle-switch";
import { AddNewEntityButton } from "@shared/case-entities";
import {EventBus} from "@/main.js";
import {getJurisdictionName} from "@shared/light-ray/utils.js";
import CanvasMenu from "@shared/saas-components/canvas-menu";
import MinusIcon from "@shared/assets/minus-icon.svg";
import Check from "@shared/light-ray/assets/svg/check.svg";
import Question from "@shared/light-ray/assets/svg/potentialQuestionMark.svg";
import CrossRed from "@shared/light-ray/assets/svg/cross-red.svg";
import {getBackgroundStatus} from "@shared/utils/monitoringTools.js";
import ConfirmDropdown from "@shared/components/confirm-dropdown/index.vue";
import CardDetailsButton from "@shared/saas-components/detail-button";
import Button from "@/components/button";

import { checkPermission } from "@shared/utils/functions";


export default {
    name: "corporate-records-card",

    data: ()=> {
        return {
            openDetails: false,
            allCardDetails: {},
            showList: false,
        }
    },

    components: {
        confirmed: Check,
        unknown: MinusIcon,
        potential: Question,
        irrelevant: CrossRed,
        nLoader,
        CanvasMenu,
        neoSwitch,
        AddNewEntityButton,
        ConfirmDropdown,
        CardDetailsButton,
        "v-button": Button,
    },

    inject: {
        isCaseCreated: { default: false }
    },

    props: {
        riskCategoriesOptions: Array,
        mainEntity: Array,
        individualData: Object,
        domainCompanyDetailsSearchList: Array,
        fieldType: Object,
        selected: Number,
        searchedQueryKeyword: "",
        showMoreInfo: {
            type: Boolean,
        },
        detail: {
            type: Object,
        },
        index: Number,
        parentIndex: {
            type: Number,
            default: null,
        },
        selectedQuery: {
            type: Object,
        },
        isSavedLoading: {
            type: Object,
            default: () => {},
        },
        monitoring: {
            type: Boolean,
            default: false,
        },
        isTypehead: {
            type: Boolean,
            default: false,
        },
        isOldVersion: {
            type: Boolean,
            default: false,
        },
        isOldVersionSelected: {
            type: Boolean,
            default: false,
        },
        showConfirmation: {
            type: Boolean,
            default: false,
        },
        autorun: {
            type: Object,
            default: null,
        },
        checkId: {
            type: String,
            default: ""
        },
        entityId: {
            type: String,
            default: ""
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters(["getProduct", "getCaseId", "getNeoCaseId", "getReadOnlyMode", "getUserFeatures"]),

        isSelected() {
            return this.selected === this.detail.current_index;
        },
        dbCorpRecord() {
            return this.getUserFeatures.includes("db__corp_record");
        },
        dbResellerCorpRecord() {
            return this.getUserFeatures.includes("dnb_reseller__corp_record");
        },
        isMonitoringTab() {
            return true;
        },
        getCardID() {
            if (this.parentIndex || this.parentIndex === 0) return `result-${this.parentIndex}.${this.index}`;
            return `result-${this.index}`;
        },
        getResultClasses() {
            let classes = "";
            if (this.detail.current_index === this.selected && this.isOldVersion) classes += "bg-blue-50 border-blue-600";
            // if (!this.detail?.api_all_data?.history_count && !this.isOldVersion && this.isMonitoringTab)
            //     classes += ' ml-2';

            if (this.selected || this.selected == 0) classes += " flex-col";
            return classes;
        },

        // confirmStateModel: {
        //     get() {
        //         return this.detail.card_status;
        //     },
        //     set(val) {
        //         this.selectDropdown(val, this.detail);
        //         this.$emit("update:confirmation", val);
        //     },
        // },
    },

    methods: {
        ...mapMutations([ "SET_SHOW_ADD_NEW_ENTITY", "SET_NEW_ENTITY_INFO" ]),

        checkPermission,
        get,
        getJurisdictionName,
        getBackgroundStatus,
        getStatusClass(status) {
            let statusClass = "rounded-full cursor-pointer p-1.5 h-7 w-7"
            if (status == 'potential' || status == 'irrelevant') {
                statusClass = "bg-red-100 " + statusClass
            }
            else if (status == 'unknown' || status == 'None') {
                statusClass = "bg-gray-300 " + statusClass
            }
            else {
                statusClass = "bg-green-50 " + statusClass
            }
            return statusClass
        },

        getStatusButtonClasses(status) {
            switch(status) {
                case 'confirmed': {
                    return "text-lime-600 border-lime-600";
                }
                case 'potential': {
                    return "text-custom-orange border-custom-orange";
                }
                case 'irrelevant': {
                    return "text-custom-red border-custom-red";
                }
                case 'unknown': {
                    return "text-gray-400 border-gray-400";
                }
                case 'none': {
                    return "text-gray-400 border-gray-400";
                }
                default : {
                    return  "text-primary border-primary-focus"
                }
            }
        },
        getHighlightShadow(status) {
            switch(status) {
                case 'confirmed': {
                    return "box-shadow: 0 4px 6px rgba(132, 204, 22, 0.6)"
                }
                case 'potential': {
                    return "box-shadow: 0 4px 6px rgba(252, 103, 19, 0.6)"
                }
                case 'irrelevant': {
                    return "box-shadow: 0 4px 6px rgba(235, 49, 49, 0.6)"
                }
                default : {
                    return  "box-shadow: 0 4px 6px rgba(209, 213, 219, 0.6)"
                }
            }
        },

        getBorderColorClass(status) {

            switch(status) {
                case 'confirmed': {
                    return "border-lime-600";
                }
                case 'potential': {
                    return "border-custom-orange";
                }
                case 'irrelevant': {
                    return "border-custom-red";
                }
                case 'unknown': {
                    return "border-gray-400";
                }
                case 'none': {
                    return "border-gray-400";
                }
                default : {
                    return  "border-gray-100"
                }
            }
        },
        getCardStatusText(status) {
            return (status === 'IRRELEVANT' ? 'FALSE MATCHES' :  status === 'UNKNOWN' ? 'UNRESOLVED' : status === "None" ? 'UNRESOLVED' : status)
        },
        getComponent(status) {
            return status;
        },
    
        // incrementPage() {
        //     this.$emit("loadMoreCompanies");
        // },
        toogleDropdown() {
            if (!checkPermission("checkOutput.edit_card")) return;
            this.showList = !this.showList;
        },
        closeDropdown() {
            this.showList = false;
        },
        async selectDropdown(event, detail) {
            this.showList = false;
            let key = event?.target?.innerText?.toUpperCase() || event?.toUpperCase();
            if(key === "FALSE MATCHES") { 
                key = "IRRELEVANT";
            } else if(key === "UNRESOLVED") { 
                key = "UNKNOWN"
            }
            if (detail.card_status === key) {
                return;
            }

            if(key === "UNKNOWN"){
                key = 'None';
            }
                let url;
                let payload = {
                    case_id: this.getNeoCaseId,
                    card_id: detail.api_all_data.doc_id,
                    entity: detail.company_name,
                    query_id: this.$route.query.query_id,
                    tool_name: this.$route?.query?.check_name || this.autorun?.headers['x-tool-name'],
                    status: key,
                    main_entity_id: this.$route.query.entityId || this.entityId,
                    source_name: "bvd",
                    card_type: "company",
                }
                url = `/service/upsert-card-result`;
                await axios.post(url, payload);  
                this.$toast.success("Successfully Updated");
                detail.card_status = key
                this.updateCardStatus();
        },
        checkCategories(item, detail) {
            let risk = this.riskCategoriesOptions.find(v => v.category == item);
            if (risk) {
                return risk.category_type === 'Risk Categories' ? 
                    ((detail?.entity_status === 'Amber') ? 'amberLabel' : 
                        'redLabel') : 
                    'greenLabel';
            }
        },
        getAnalyserSource(key, matchKeyword, matchSource) {
            if (key && typeof key === "string" && key?.toLowerCase() === matchKeyword) return matchSource || "name";
            else return matchSource ? "name" : "company_name";
        },

        getPreviousNames(detail) {
            return detail?.api_all_data?.company?.previous_names?.map((item) => item.company_name) || [];
        },

        checkPreviousNames(detail) {
            return detail.company_name && !detail.company_name.toLowerCase().includes(this.searchedQueryKeyword?.toLowerCase()) && this.getPreviousNames(detail).length > 0;
        },

        handleFetchMore() {
            // this.selected = info;
            this.$emit("moreInfo", true);
        },
        getJurisdictionFlag(code) {
            code = code.toLowerCase();
            code = code === "uk" ? "gb" : code;
            let country_code = code.split("_")[0];
            if (country_code.length > 2) {
                let country_name = JURISDICTION_MAP.find((ele) => ele.jurisdiction.code == country_code.toLowerCase() && !ele.flag_code)?.jurisdiction?.full_name;
                if (country_name) {
                    let flag_code = JURISDICTION_MAP.find((ele) => ele.jurisdiction.full_name == country_name && ele.jurisdiction.code !== country_code.toLowerCase());
                    if (flag_code?.jurisdiction?.code) {
                        country_code = flag_code?.jurisdiction?.code.toLowerCase();
                    }
                }
            }
            return this.$store.getters?.getIconsData?.find((e) => e.key == `flag_${country_code}`)?.icon_data ?? "";
        },
        formatDate(dt) {
            let parsed_dt = DateTime.fromISO(dt);
            if (!parsed_dt.invalid) return parsed_dt.toFormat("EEE, MMM dd yyyy");
            return dt;
        },
        async showMoreData(detail, index) {
            if (this.disabled || !checkPermission("checkOutput.detailed_card")) return;

            detail.loadingMore = true;
            this.$forceUpdate();
            this.selected = index;
            await this.$emit("showMoreData", {...detail, index: index, isOldVersion: this.isOldVersion});
            detail.loadingMore = false;
            this.$forceUpdate();
        },
        async handleDone({target}, oldVersion) {
            let detail = this.detail;
            detail.api_all_data.changes_viewed = target.checked;
            let payload = [
                {
                    doc_id: detail.api_all_data.doc_id,
                    sources: [this.fetchKey(detail.key)],
                    cascaded: false,
                },
            ];
            EventBus.$emit("handleDone", {card: detail, index: this.index, oldVersion});
            this.$forceUpdate();
            try {
            await axios.post("/service/mark-monitered-data-visited", {
                // sources: [this.fetchKey(detail.key)],
                // doc_id: detail.api_all_data.doc_id,
                card_info: payload,
                case_id: this.getNeoCaseId,
                visited: target.checked,
            });
             }
             catch(err) {
                 console.log(err)
            }
            this.updateComparisonReferences(detail);
            setTimeout(()=>{
                this.$emit('getQueriesMonitoring')
                this.$toast.success('Card Acknowledged')
            }, 1000)
        },

        fetchKey(sourceKey) {
            let corpKey = sourceKey;
            // if(key==='officer')
            switch (corpKey) {
                case "officer":
                    corpKey = "opencorporates";
                    break;
                case "officer_clarifiedBy":
                    corpKey = "clarifiedby";
                    break;
                case "officer_companyWatch":
                    corpKey = "companywatch";
                    break;
                case "officer_bvd":
                    corpKey = "bvd";
                    break;
                default:
                    break;
            }
            return corpKey.toLowerCase();
        },

        async handleMonitoring() {
            let detail = this.detail;
            detail.api_all_data.monitoring_status = !detail.api_all_data.monitoring_status;
            EventBus.$emit("handleMonitoring", {card: detail, index: this.index});
            this.$forceUpdate();
            let sourceKey = this.fetchKey(detail.key);
            try {
                await axios.post("/service/data-monitoring", {
                    product_name: this.getProduct.name,
                    product_id: this.getProduct.key,
                    case_id: this.getNeoCaseId,
                    query: this.selectedQuery.query_payload,
                    tool: this.$route?.query?.check_name || this.autorun?.headers['x-tool-name'],
                    frequency: 7,
                    monitoring_type: "card",
                    inherit_product_settings: false,
                    inherit_product_id: "",
                    card_id: detail.api_all_data.doc_id,
                    card_source: sourceKey.toLowerCase(),
                    status: detail.api_all_data.monitoring_status ? "enabled" : "disabled",
                });
                this.$toast.success("Monitoring updated successfully !");
            } catch (error) {
                console.log("err", error);
                this.$toast.error("Something Went Wrong !");
            }
        },

     
        getResultsID(index) {
            return `result-${index}`;
        },
        getFormattedTime(ts) {
            let date = DateTime.fromISO(ts);
            return date.toFormat("dd MMM yyyy");
        },
        closeCanvas() {
            this.openDetails = false
        },
        updateCardStatus() {
            this.$emit("handleStatusUpdate")
        },
        showCardDetails(e,detail) {
            e.stopPropagation()
            this.openDetails = true
            let cardDetails = {
                case_id: this.getNeoCaseId,
                card_id: detail.api_all_data.doc_id,
                entity: detail.company_name,
                query_id: this.$route.query.query_id,
                tool_name: this.$route.query.check_name || this.autorun?.headers['x-tool-name'],
                status: detail.card_status,
                main_entity_id: this.$route?.query?.entityId || this.entityId,
                source_name: "bvd",
                card_type: "company",
                relations: [],
                bespoke: detail.bespoke,
                entity_status: detail.entity_status,
                customer_notes: detail.customer_notes,
                notes: detail.research_notes,
                risk_categories: detail.risk_categories,
                screenshot: detail.screenshot
            }
            this.allCardDetails = cardDetails
            
            // if (!this.isSavedLoading.loading && this.isSavedLoading.loadingCardIndex !== detail.api_all_data.doc_id) EventBus.$emit("handleResultCorporateSave", detail);
        },

        updateComparisonReferences(data) {
            EventBus.$emit("updateMonitoringCascadingTimeline", {
                newData: data,
            });
        },

        showAddEntityFlow(temporaryName) {
            this.SET_SHOW_ADD_NEW_ENTITY(true);
            this.SET_NEW_ENTITY_INFO({ key: "temporary_name",  value: temporaryName });
        },
    },
};
